import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Test Week`}</em></p>
    <p>{`-Deadlifts to a 1RM`}</p>
    <p>{`-Max Height Box Jump`}</p>
    <p>{`-1 attempt, max HSPU’s `}<em parentName="p">{`(scaled to max pushups)`}</em></p>
    <p>{`-1 attempt, max Bar Muscle Ups`}</p>
    <p>{`-1000M Ski Erg for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Time is running out to get your team registered for the Granite
Games Fall Throwdown here at The Ville September 14th!  We’re going to
take 1 more team so if you want in register soon or you’ll miss out.
 Cut off is this Sunday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Learn more about the Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      